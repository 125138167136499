import React from 'react'
import {Col}  from 'react-bootstrap'
import { Link } from 'gatsby'
import * as styles from '../styles/list-item.module.css'

export default function ArticleMiniPreview({title, link}) {
    return (

        <Col xs={12} md={4}>
            <div className={styles.mini}>
                <Link to={link}>
                        {title}
                </Link>  
            </div>         
        </Col>
    )
}